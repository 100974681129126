import { render, staticRenderFns } from "./GalleryModal.vue?vue&type=template&id=dbf47ef2"
import script from "./GalleryModal.vue?vue&type=script&lang=js"
export * from "./GalleryModal.vue?vue&type=script&lang=js"
import style0 from "./GalleryModal.vue?vue&type=style&index=0&id=dbf47ef2&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports